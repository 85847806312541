<script>
  import { onMount, onDestroy } from 'svelte';
  import Button from '@components/RippleButton.svelte';

  export let showCloseButton = false;
  export let novalidate = true;
  export let noclose = false;
  export let noform = false;
  export let open;
  export let onsubmit;

  // Config
  const isOpenClass = "modal-is-open";
  const openingClass = "modal-is-opening";
  const closingClass = "modal-is-closing";
  const animationDuration = 300; // ms
  const html = document.documentElement;

  export let dialog;


  //export let icon;
  let timeout = null;

  function toggle(node, attributes) {
    const setAttributes = (attrs) => {
      Object.entries(attrs).forEach(([attr, value]) => {
        node.toggleAttribute(attr, value);
      });
    };

    // Initial setting of the attributes
    setAttributes(attributes);

    // Return an update function for reactivity
    return {
      update: (newAttributes) => setAttributes(newAttributes)
    };
  }
  export function show(event) {
    //console.log('show()', event, dialog);
    event?.preventDefault();
    clearTimeout(timeout);
    html.classList.remove(closingClass);
    dialog.showModal();
    html.classList.add(isOpenClass, openingClass);
    timeout = setTimeout(() => {
      html.classList.remove(openingClass);
    }, animationDuration);
    updateWidth();
  }

  export function updateWidth() {
    const article = dialog.firstElementChild;
    //console.log('Dialog article width: ', dialog, article, article.offsetWidth);
    article.style.width = `${article.offsetWidth}px`;
    //article.style.maxWidth = "none";
    //console.log('Dialog article result: ', article.style.width, article.style.maxWidth);
  }

  export function hide(event) {
    //console.log('hide()', event, dialog);
    //clicked inside, so ignore
    if (noclose || (event.target != dialog)) {
      return;
    }
    event?.preventDefault();
    close(event);
  }

  export function close(event) {
    console.log('close()', event);
    event?.preventDefault();
    clearTimeout(timeout);
    if (!dialog.open) {
      console.log('Dialog is not open, nothing to close.');
      return;
    }
    console.log('Dialog is open, closing soon after animation.');
    html.classList.remove(openingClass);
    html.classList.add(closingClass);
    timeout = setTimeout(() => {
      html.classList.remove(closingClass, isOpenClass);
      console.log('Dialog being closed now.');
      //This will trigger another run of the close function
      dialog.close();
    }, animationDuration);
  }

  function keypress(event) {
    if (event.key === 'Escape') {
      event?.preventDefault();

      if (noclose) {
        return;
      }

      close(event);
    }
    event.stopPropagation()
  }

  onMount(() => {
    document.body.appendChild(dialog);
  });

  onDestroy(() => {
    clearTimeout(timeout);
    html.classList.remove(openingClass, closingClass, isOpenClass);
    dialog?.remove();
  });

  function onclick(event) {
    console.log('onclick() from Dialog.svelte');
    show(event);
  }

  function submit(event) {
    console.log('submit()', event);
  }

</script>

<style>
  dialog {
    overflow: auto;
    scrollbar-gutter: stable;
  }

  dialog article {
    max-width: var(--max-width);
    width: fit-content;
    border-radius: calc(var(--pico-border-radius) * 3);
    --pico-block-spacing-horizontal: calc(var(--pico-spacing) * 1.25);
    --pico-block-spacing-vertical: calc(var(--pico-spacing) * 1);
    overflow: hidden;
    max-height: unset;

    margin: auto 0;

    :global(input:not([type="checkbox"], [type="radio"]), select, textarea) {
      margin-bottom: calc(var(--pico-spacing) * 0.5);
    }

    :global(:where(input, select, textarea) + small) {
      margin-top: calc(var(--pico-spacing) * -.35);
    }

    :global(input[type="checkbox"] + small) {
      margin-top: calc(var(--pico-spacing) * 0);
    }

    button[rel="prev"] {
      width: 1.5rem;
      height: 1.5rem;
      background-size: auto 1.5rem;
      opacity: 1;
      margin-top: 0.1rem;
    }

    header {
      padding-top: calc(var(--pico-block-spacing-vertical) * 0.7);
      padding-bottom: calc(var(--pico-block-spacing-vertical) * 0.55);
      :global(h2) {
        --pico-font-size: 1.35rem;
      }
    }

    :global(footer) {
      padding: calc(var(--pico-block-spacing-vertical) * 1) var(--pico-block-spacing-horizontal);
      margin-block: 0 calc(var(--pico-block-spacing-vertical) * -1);
      margin-inline: calc(var(--pico-block-spacing-horizontal) * -1);
      background-color: var(--pico-card-sectioning-background-color);
      border-top: var(--pico-border-width) solid var(--pico-card-border-color);
      /*
      border-bottom-right-radius: var(--pico-border-radius);
      border-bottom-left-radius: var(--pico-border-radius);
       */
      text-align: right;
      display: flex;
      flex-direction: row-reverse;
      gap: calc(var(--pico-form-element-spacing-horizontal) * 1);
    }
  }

  dialog article :global(footer button[type="submit"]) {
    width: auto;
    margin-bottom: 0;
  }

</style>

<dialog bind:this={dialog} on:close={close} on:click={hide} on:keydown={keypress} {open}>
  <article {...$$restProps}>
    <header>
      {if ! noclose}<button rel="prev" on:click={close}></button>{/if}
        <slot name="header" />
    </header>
    {if noform}
      <slot />
    {else}
      <form method="dialog" on:submit={onsubmit} use:toggle={{novalidate: novalidate}}>
        <slot />
        {if showCloseButton}
          <footer><Button onclick={close} autofocus>Close</Button></footer>
        {/if}
      </form>
    {/if}
  </article>
</dialog>

<slot name="trigger" {onclick}></slot>

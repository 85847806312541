<script>
  import { ripple } from '@lib/ripple.js';
  import Icon from '@components/IconWithLabel.svelte';

  let { icon, iconSize = '1.25rem', children, ...rest } = $props();

  let buttonElement = $state();

</script>

<style>
  button {
    position: relative;
    overflow: hidden;
  }

  Icon.children {
    margin-right: 0.1rem !important;
  }

</style>

<button use:ripple {...rest} >
  {if icon}
    <Icon {icon} size={iconSize} class:children {children} />
  {else}
    {@render children?.()}
  {/if}
</button>
